.genBox {
  background-color: #24232c;
  padding: 1.5rem;
  color: white;
  width: fit-content;
}

.checkBoxContainer {
  display: flex;
  flex-direction: column;
}
